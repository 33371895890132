import { v4 as uuidv4 } from "uuid";
import graphQlList from "../../../../libs/prelems/src/lib/graphql/fetch";
import { CHANNEL } from "./trackingConstants";

export const getDeviceType = () => {
  const viewportWidth = window?.innerWidth;
  if (viewportWidth < 768) {
    return "Mobile";
  } else if (viewportWidth < 1024) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export const getDeviceOS = () => {
  const ua = navigator?.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/i.test(ua) && !globalThis.MSStream) {
    return "iOS";
  } else if (/Macintosh|Mac OS X/i.test(ua)) {
    return "macOS";
  } else if (/Windows NT/i.test(ua)) {
    return "Windows";
  } else if (/Linux/i.test(ua)) {
    return "Linux";
  } else {
    return "Other";
  }
};

export const getRpiInstance = () => {
  if (typeof window !== "undefined" && globalThis?.rpiWebClient) {
    return true;
  }
  return false;
};

export const getUserId = () => {
  return localStorage?.getItem("userId") || "anonymous";
};

export const getCartId = () => {
  return localStorage?.getItem("ecommerceCartId") ?? "";
};

export const getLocationStorage = () => {
  return localStorage?.getItem("locationData");
};

/**
 * Constructs a full address string from a card object.
 *
 * @param {Object} card - The card object containing address details.
 * @param {string} card.firstName - The first name of the cardholder.
 * @param {string} card.lastName - The last name of the cardholder.
 * @param {string} card.address - The address of the cardholder.
 * @param {string} card.city - The city of the cardholder.
 * @param {string} card.country - The country of the cardholder.
 * @returns {string} A formatted string containing the full address details.
 */
export const getPipedFullAddress = (fullAddress) => {
  return `firstName:${fullAddress?.title || fullAddress?.firstName}|lastName:${fullAddress?.last_name || fullAddress?.lastName}|address:${fullAddress?.street_name || fullAddress?.address}|city:${fullAddress?.city}|state:${fullAddress?.state}|country:${fullAddress?.country}`;
};

export const getPipedData = (data) => {
  if (data && Array.isArray(data)) {
    return data.join("|");
  }
  return "";
};

/**
 * Prepares an object for RPI (Real-time Processing Interface) by transforming it into an array of key-value pairs.
 *
 * @param {Object} dataTemplate - The object containing data to be transformed.
 * @returns {Array<{ Name: string, Value: any }>} An array of objects, each containing a 'Name' and 'Value' property.
 */
export const prepareObjectForRPI = (dataTemplate) => {
  return Object.keys(dataTemplate).reduce((acc: { Name: string; Value: any }[], key) => {
    return [
      ...acc,
      {
        Name: key,
        Value: dataTemplate?.[key],
      },
    ];
  }, []);
};

export const getPageName = () => {
  const fragments = window?.location?.href?.split("/");
  const last = fragments?.[fragments?.length - 1];
  return last;
};

const genrateCardData = (list) => {
  if (list?.length > 0) {
    const cardList = list.map((item) => {
      return `${item?.content_type}:${item?.title}`;
    });
    return cardList?.length > 0 ? cardList.join("|") : null;
  } else return "";
};

const getUniqueImpressionId = () => {
  const user = getUserId(); // Identifier for web traffic
  const timestamp = Date.now(); // Current time in milliseconds
  return `${uuidv4()}-${timestamp}-${user}-${CHANNEL}`;
};

export const getdefaultEventObj = (pageName, secondaryArgs, cardData = "") => {
  const geolocationDataString = getLocationStorage();
  let geolocationData;
  if (geolocationDataString) {
    geolocationData = JSON.parse(geolocationDataString);
  }
  // eslint-disable-next-line no-console
  console.log("geolocationData", geolocationData);
  const { prelemBaseEndpoint: { environment = "", language = "en", site_host = "" } = {} } =
    secondaryArgs;

  return {
    page_name: pageName || getPageName(),
    page_url: window?.location?.href,
    language: language,
    created_datetime: new Date().toISOString(),
    env_name: environment,
    site_host: site_host,
    card_list: genrateCardData(cardData) || "",
    channel: CHANNEL,
    geo_country: geolocationData?.country_name,
    geo_country_code: geolocationData?.country_code3,
    geo_region: geolocationData?.state_prov,
    geo_city: geolocationData?.city,
    geo_zipcode: geolocationData?.zipcode,
    user_agent: navigator?.userAgent || "",
    device_type: getDeviceType(),
    thumb_impression: getUniqueImpressionId(),
    // userId: getUserId(),
    // device_type: getDeviceType(),
    // device_browser: getBrowser(),
    // device_os: getDeviceOS(),
    // country: geolocationData?.country_name,
    // city: geolocationData?.city,
    // ip: geolocationData?.ip,
  };
};
export const extractEventTypes = (eventSchemaList) => {
  if (!Array.isArray(eventSchemaList)) return [];
  return eventSchemaList.map(({ event_type }) => event_type);
};

export const extractEventFields = (eventSchemaList) => {
  if (!Array.isArray(eventSchemaList)) return [];
  return eventSchemaList.map(({ event_type, event_schema }) => {
    const fieldNames = event_schema?.fields?.map(({ name }) => name) || [];
    return { [event_type]: fieldNames };
  });
};
export const getCustomEventSchemaList = async (eventData, secondaryArgs, template) => {
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host } = {} } =
    secondaryArgs;
  const eventTypes = sessionStorage.getItem("eventTypes");
  const eventFields = sessionStorage.getItem("eventFields");
  if (!eventTypes && !eventFields) {
    try {
      const { data } = await graphQlList?.getCustomEventSchemaList(
        {
          pagination: {
            start: 1,
            rows: 50,
          },
          sort: "desc",
        },
        language,
        site_host,
        deliveryEndPointGq,
      );
      if (data?.users_getCustomEventSchemaList?.eventSchemaList) {
        const { eventSchemaList } = data?.users_getCustomEventSchemaList || {};
        const eventTypesTotrack = extractEventTypes(eventSchemaList);
        const eventFieldTotrack = extractEventFields(eventSchemaList);
        sessionStorage.setItem("eventTypes", JSON.stringify(eventTypesTotrack));
        sessionStorage.setItem("eventFields", JSON.stringify(eventFieldTotrack));
        createPostEventDataByName(eventData, secondaryArgs, template);
      }
    } catch (err: any) {
      console.error("error", err);
    }
  } else {
    const parsedEventTypes = eventTypes ? JSON.parse(eventTypes) : [];
    if (parsedEventTypes?.includes(eventData?.eventName)) {
      createPostEventDataByName(eventData, secondaryArgs, template);
    }
  }
};
export const createPostEventDataByName = (eventData, secondaryArgs, template) => {
  const userLoginDetails = localStorage.getItem("userLoginDetails");
  const eventTypes = sessionStorage.getItem("eventTypes");
  const eventFields = sessionStorage.getItem("eventFields");
  if (userLoginDetails && eventTypes && eventFields) {
    const parsedLoginDetails = JSON.parse(userLoginDetails);
    const parsedEventTypes = JSON.parse(eventTypes);
    const parsedEventFields = JSON.parse(eventFields);
    const data = {
      input: {
        type: eventData?.eventName,
        customerData: {
          email: parsedLoginDetails?.data?.email_id,
        },
        eventDate: new Date().toISOString(),
        body: getCustomBody({
          parsedEventTypes,
          parsedEventFields,
          eventData,
          secondaryArgs,
          template,
        }),
      },
    };
    postEventDetail(secondaryArgs, data);
  }
};
export const getCustomBody = ({
  parsedEventTypes,
  parsedEventFields,
  eventData,
  secondaryArgs,
  template,
}) => {
  if (parsedEventTypes && parsedEventFields && eventData?.eventName) {
    const actionObject = parsedEventFields?.find((item) =>
      Object.prototype.hasOwnProperty.call(item, eventData?.eventName),
    );
    if (actionObject) {
      const keys = actionObject[eventData?.eventName];
      const keyValuePairs = {};
      keys.forEach((key) => {
        keyValuePairs[key] = secondaryArgs?.prelemBaseEndpoint?.[key] || template?.[key] || "";
      });
      return keyValuePairs;
    } else {
      return {};
    }
  } else {
    return {};
  }
};
export const postEventDetail = async (secondaryArgs, body) => {
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host } = {} } =
    secondaryArgs;
  await graphQlList.postCustomEventData(body, language, site_host, deliveryEndPointGq);
};
