import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "igmore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const httplink = (endPoint) =>
  createHttpLink({
    uri: endPoint,
    credentials: "include",
  });

const middleware = (lang: string, host: string) => {
  const updateLanguageHeader = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        language: lang,
        site_host: host,
        sitehost: host,
        sitename: host,
      },
    };
  });

  return updateLanguageHeader;
};
export const getGraphQlInstance = (lang: string, host: string, endPoint: string) => {
  const graphqlInstance = new ApolloClient({
    link: middleware(lang, host).concat(httplink(endPoint)),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });
  return graphqlInstance;
};
export default getGraphQlInstance;
