export const PAGE_VIEW_ACTION = {
  eventName: "page_view", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "page impression", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

//RPI TRACKING CONSTANTS
export const RPI_TAG_SELECTION = {
  eventName: "button_click", //string ->The name of the event.
  stateValue: 1, //string | null ->The value or count to assign to the event.
  extraParam: "selected tag for rpi", //string | null ->The additional details to assign to the event.
  contentId: null, //string | null ->The content ID to register the metric for (Goal Drive Assets only).
  data: [], //Array<ApiEventMetadata> -> The metadata to assign to the event.
};

export const CHANNEL = "web";
export const BUTTON_CLICK = "button_click";
export const MY_STORIES_FEED = "my_stories_feed";
export const ANONYMOUSDEVICE = "AnonymousDevice";
export const ANONYMOUSVISITORID = "AnonymousVisitorID";

export const IMPRESSION = "impression";
export const CONTENT_STATUS = {
  FREE: "free",
  PREMIUM: "premium",
  REGISTERED: "registered",
};
