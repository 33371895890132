import getGraphQlInstance from "./graphqlConfig";
import {
  ADD_OR_REMOVE_COUPONS,
  CUSTOM_EVENT_SCHEMA_LIST,
  EXPIRING_POINTS,
  FETCH_CONTENT_DATA,
  GET_AVAILABLE_REWARDS_QUERY,
  GET_MY_REWARD_AND_USED_REWARDS_QUERY,
  GET_REWARDS_DETAIL_BY_ID_QUERY,
  POST_CUSTOM_EVENT,
  REDEEM_COUPON,
} from "./queries";

const graphQlList = {
  fetchContentData: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: FETCH_CONTENT_DATA,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
  fetchAvailableRewardData: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_AVAILABLE_REWARDS_QUERY,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { loading, errors, data };
  },
  fetchMyRewardAndUsedRewardData: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_MY_REWARD_AND_USED_REWARDS_QUERY,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { loading, errors, data };
  },
  fetchRewardDetailByID: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_REWARDS_DETAIL_BY_ID_QUERY,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { loading, errors, data };
  },
  redeemCoupon: async <T>(input: T, lang: string, host: string, endPoint: string): Promise<any> => {
    const { errors, data } = await getGraphQlInstance(lang, host, endPoint).mutate({
      mutation: REDEEM_COUPON,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { errors, data };
  },
  expiringCoupon: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: EXPIRING_POINTS,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { loading, errors, data };
  },
  getCustomEventSchemaList: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: CUSTOM_EVENT_SCHEMA_LIST,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { loading, errors, data };
  },
  postCustomEventData: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { errors, data } = await getGraphQlInstance(lang, host, endPoint).mutate({
      mutation: POST_CUSTOM_EVENT,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { errors, data };
  },
  addRemoveCoupons: async <T>(
    input: T,
    lang: string,
    host: string,
    endPoint: string,
  ): Promise<any> => {
    const { errors, data } = await getGraphQlInstance(lang, host, endPoint).mutate({
      mutation: ADD_OR_REMOVE_COUPONS,
      variables: input,
      fetchPolicy: "network-only",
    });

    return { errors, data };
  },
};

export default graphQlList;
